import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function CheckoutFailurePage() {
  return (
    <Layout>
      <SEO title="Checkout Success" />
      <div className="py-12 bg-white">
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            Failure
          </p>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Ugh!
          </h3>
          <p className="mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
            Something went wrong with the checkout and it did not successfully
            complete. Sorry. Please try again, or email me for help. -Jamie
          </p>
          <p className="mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
            <a href="mailto:jamie@spectatin.com">jamie@spectatin.com</a>
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default CheckoutFailurePage;
